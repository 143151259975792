import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Persona from "./routes/Persona";
import { Stack } from "@mui/material";

function App() {
  return (
    <Stack sx={{ marginTop: 2 }}>
      <Persona />
    </Stack>
  );
}

export default App;
