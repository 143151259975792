import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

const SimpleForm = () => {
  const [business_model, set_business_model] = useState("");
  const [product, set_product] = useState("");
  const [persona, set_persona] = useState("");
  const [loading, setLoading] = useState(false);

  const makeRequest = () => {
    setLoading(true);
    fetch(`http://localhost:8000?business_model=${business_model}&product=${product}`)
      .then((response) => response.json())
      .then((jsonData: { persona: string }) => {
        set_persona(jsonData.persona);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Stack spacing={3}>
      <TextField
        multiline
        label="Dein Business model"
        value={business_model}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          set_business_model(event.target.value);
        }}
      />
      <TextField
        multiline
        label="Dein Produkt"
        value={product}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          set_product(event.target.value);
        }}
      />
      <Stack direction={"row"}>
        <Button variant="contained" color="primary" onClick={makeRequest} disabled={loading}>
          Persona erstellen
        </Button>
      </Stack>
      {loading && <CircularProgress />}
      {!loading && persona && (
        <>
          <TextField
            multiline
            label="Persona"
            value={persona}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              set_persona(event.target.value);
            }}
          />
        </>
      )}
    </Stack>
  );
};

export default SimpleForm;
